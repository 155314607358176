/* .App {
  text-align: center;
} */

/* .navi {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.navitem {
  padding: 20px;
}

a {
  text-decoration: none;
  color: rgb(46, 46, 46);
}

hr {
  border: 0.1px solid rgb(15, 2, 37);
  border-radius: 3px;
} */

.login-btn {
  color: #4caf50;
  background-color: white;
  font-size: 14px;
  border-radius: 8px;
  border: 2px solid #4caf50;
}

.login-btn:hover {
  color: white;
  background-color: #4caf50;
}
